<div ngModelGroup="override">
    <h4 class="unique-trait-override-title mt-8" translate>Scan limits</h4>
    <div class="card-body">
        <mon-form-field-input
                [name]="'max_domains'"
                [(ngModel)]="customer.subscription.plan_overrides.max_domains"
                monLabel="{{ 'max_domains' | monTranslatePlanTraits }}"
                monPlaceholder="{{ 'max_domains' | monTranslatePlanTraits }}"
                [type]="'number'">
        </mon-form-field-input>
        <mon-form-field-input
            [name]="'max_scanned_pages'"
            [(ngModel)]="customer.subscription.plan_overrides.max_scanned_pages"
            monLabel="{{'max_scanned_pages' | monTranslatePlanTraits}}"
            monPlaceholder="{{'max_scanned_pages' | monTranslatePlanTraits}}"
            [type]="'number'">
        </mon-form-field-input>
    </div>

    <h4 class="unique-trait-override-title" translate>Scan features</h4>
    <div class="card-body">
        <mon-form-field-select
                (changeEvent)="selectionChange.emit()"
                [monName]="'scan_interval'"
                monLabel="{{ 'scan_interval' | monTranslatePlanTraits }}"
                [monReturnValue]="'value'"
                [monAllowClear]="true"
                [monPlaceholder]="defaultOptionTranslation"
                [monOptions]="scanFrequenciesOptions"
                [(model)]="customer.subscription.plan_overrides.scan_interval">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'custom_scan_interval'"
               [(model)]="customer.subscription.plan_overrides.custom_scan_interval"
               (changeEvent)="selectionChange.emit()"
               monLabel="{{ 'custom_scan_interval' | monTranslatePlanTraits }}"
               [monAllowClear]="true"
               [monPlaceholder]="defaultOptionTranslation"
               [monOptions]="booleanDropdownOptions"
               [monReturnValue]="'value'">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'on_demand_scans'"
            [(model)]="customer.subscription.plan_overrides.on_demand_scans"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'on_demand_scans' | monTranslatePlanTraits }}"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [monReturnValue]="'value'">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'single_page_scan_any_url'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'single_page_scan_any_url' | monTranslatePlanTraits}}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.single_page_scan_any_url">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'html_scans'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'html_scans' | monTranslatePlanTraits}}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.html_scans">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'image_metadata'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'image_metadata' | monTranslatePlanTraits}}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.image_metadata">
        </mon-form-field-select>
    </div>

    <h4 class="unique-trait-override-title" translate>Modules</h4>
    <div class="card-body">
        <mon-form-field-select [monName]="'accessibility'"
            [(model)]="customer.subscription.plan_overrides.accessibility"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'accessibility' | monTranslatePlanTraits }}"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            monReturnValue="value">
        </mon-form-field-select>
        <ng-container *ngIf="customer.subscription.plan_overrides.accessibility">
            <div class="row">
                <div class="col-sm-offset-8 col-sm-40">
                    <mon-form-field-select [monName]="'mobile_guidelines'"
                                monLabel="{{ 'mobile_guidelines' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.mobile_guidelines"
                                *ngIf="canUseMobileGuidelines">
                    </mon-form-field-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-8 col-sm-40">
                    <mon-form-field-select
                        [monName]="'clarity_pdf_accessibility'"
                        monLabel="{{ 'clarity_pdf_accessibility' | monTranslatePlanTraits }}"
                        [monReturnValue]="'value'"
                        (changeEvent)="selectionChange.emit()"
                        [monPlaceholder]="defaultOptionTranslation"
                        [monAllowClear]="true"
                        [monOptions]="setupClarityOptions"
                        [(model)]="customer.subscription.plan_overrides.clarity_pdf_accessibility">
                    </mon-form-field-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-8 col-sm-40">
                    <mon-form-field-select
                        (changeEvent)="selectionChange.emit()"
                        [monName]="'clarity_pdf_remediation'"
                        monLabel="{{ 'clarity_pdf_remediation' | monTranslatePlanTraits }}"
                        [monReturnValue]="'value'"
                        [monAllowClear]="true"
                        [monPlaceholder]="defaultOptionTranslation"
                        [monOptions]="booleanDropdownOptions"
                        [(model)]="customer.subscription.plan_overrides.clarity_pdf_remediation">
                    </mon-form-field-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-offset-8 col-sm-40">
                    <mon-form-field-select [monName]="'accessibility_summary'"
                                            monLabel="{{ 'accessibility_summary' | monTranslatePlanTraits}}"
                                            (changeEvent)="selectionChange.emit()"
                                            [monReturnValue]="'value'"
                                            [monAllowClear]="true"
                                            [monPlaceholder]="defaultOptionTranslation"
                                            [monOptions]="booleanDropdownOptions"
                                            [(model)]="customer.subscription.plan_overrides.accessibility_summary">
                    </mon-form-field-select>
                    <span *ngIf="customer.subscription.plan_overrides.accessibility_summary === true" class="col-sm-offset-8 col-sm-40 mb-2 limit-info-text" translate>
                        Be aware that enabling this trait will remove FastTrack, Guidelines, Checklist and Pages with Checks from the Accessibility module.
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-8">
                <div class="module-title text-right" translate>Quality Assurance</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select [monName]="'qa_links'"
                    monLabel="{{ 'qa_links' | monTranslatePlanTraits}}"
                    (changeEvent)="selectionChange.emit()"
                    [monReturnValue]="'value'"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="booleanDropdownOptions"
                    [(model)]="customer.subscription.plan_overrides.qa_links">
                </mon-form-field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select [monName]="'qa_spellcheck'"
                    monLabel="{{ 'qa_spellcheck' | monTranslatePlanTraits}}"
                    (changeEvent)="selectionChange.emit()"
                    [monReturnValue]="'value'"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="booleanDropdownOptions"
                    [(model)]="customer.subscription.plan_overrides.qa_spellcheck">
                </mon-form-field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select
                    [monName]="'spell_check_engine_priority'"
                    (changeEvent)="selectionChange.emit()"
                    monLabel="{{ 'spell_check_engine_priority' | monTranslatePlanTraits}}"
                    [monAllowClear]="true"
                    monReturnValue="value" name-attr="name"
                    [monMultiple]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="spellcheckEngineOptions"
                    [(model)]="customer.subscription.plan_overrides.spell_check_engine_priority">
                </mon-form-field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select [monName]="'readability'"
                    monLabel="{{ 'readability' | monTranslatePlanTraits }}"
                    (changeEvent)="selectionChange.emit()"
                    [monReturnValue]="'value'"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="booleanDropdownOptions"
                    [(model)]="customer.subscription.plan_overrides.readability">
                </mon-form-field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select [monName]="'qa_summary'"
                                    monLabel="{{ 'qa_summary' | monTranslatePlanTraits}}"
                                    (changeEvent)="selectionChange.emit()"
                                    [monReturnValue]="'value'"
                                    [monAllowClear]="true"
                                    [monPlaceholder]="defaultOptionTranslation"
                                    [monOptions]="booleanDropdownOptions"
                                    [(model)]="customer.subscription.plan_overrides.qa_summary">
                                </mon-form-field-select>
                <span *ngIf="customer.subscription.plan_overrides.qa_summary === true" class="col-sm-offset-8 col-sm-40 mb-2 limit-info-text" translate>
                    Be aware that enabling this trait will remove Spellcheck and the Important Highlights card from the QA module.
                </span>
            </div>
        </div>
        <mon-form-field-select [monName]="'policies_all'"
            monLabel="{{ 'policies_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.policies_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'seo_all'"
            monLabel="{{ 'seo_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.seo_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'statistics'"
            [(model)]="customer.subscription.plan_overrides.statistics"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'statistics' | monTranslatePlanTraits }}"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [monReturnValue]="'value'">
        </mon-form-field-select>
        <div class="row" *ngIf="customer.subscription.plan_overrides.statistics">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select
                    [monName]="'domain_group_statistics'"
                    (changeEvent)="selectionChange.emit()"
                    [(model)]="customer.subscription.plan_overrides.domain_group_statistics"
                    monLabel="{{ 'domain_group_statistics' | monTranslatePlanTraits }}"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="booleanDropdownOptions"
                    [monReturnValue]="'value'">
                </mon-form-field-select>
            </div>
        </div>
        <mon-form-field-select [monName]="'data_protection'"
            monLabel="{{ 'data_protection' | monTranslatePlanTraits }}"
            (changeEvent)="selectionChange.emit()"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.data_protection">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'cookie_banner'"
            monLabel="{{ 'cookie_banner' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.cookie_banner">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'inventory_all'"
            monLabel="{{ 'inventory_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.inventory_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'heartbeat'"
            monLabel="{{ 'heartbeat' | monTranslatePlanTraits }}"
            [monReturnValue]="'value'"
            (changeEvent)="selectionChange.emit()"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.heartbeat">
        </mon-form-field-select>
        <div class="row" *ngIf="customer.subscription.plan_overrides.heartbeat">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select
                    [monName]="'uptime_intervals'"
                    (changeEvent)="selectionChange.emit()"
                    monLabel="{{ 'uptime_intervals' | monTranslatePlanTraits}}"
                    [monAllowClear]="true"
                    monReturnValue="value" name-attr="name"
                    [monMultiple]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="intervalOptions"
                    [(model)]="customer.subscription.plan_overrides.uptime_intervals">
                </mon-form-field-select>
            </div>
        </div>

        <mon-form-field-select
            [monName]="'performance_tracking'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'performance_tracking' | monTranslatePlanTraits }}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.performance_tracking">
        </mon-form-field-select>
        <div class="row">
            <div class="col-sm-offset-8 col-sm-40">
                <mon-form-field-select
                    [monMultiple]="true"
                    (changeEvent)="selectionChange.emit()"
                    [monName]="'performance_tracking_allowed_frequencies'"
                    monLabel="{{ 'performance_tracking_allowed_frequencies' | monTranslatePlanTraits }}"
                    [monReturnValue]="'value'"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="performanceFrequencies.PERFORMANCE_FREQUENCIES"
                    [(model)]="customer.subscription.plan_overrides.performance_tracking_allowed_frequencies">
                </mon-form-field-select>
                <mon-form-field-input
                    [name]="'performance_tracking_max_page_profiles'"
                    [(ngModel)]="customer.subscription.plan_overrides.performance_tracking_max_page_profiles"
                    monLabel="{{ 'performance_tracking_max_page_profiles' | monTranslatePlanTraits }}"
                    [monPlaceholder]="defaultOptionTranslation"
                    [type]="'number'">
                </mon-form-field-input>
                <mon-form-field-input
                    [name]="'performance_tracking_max_pages'"
                    [(ngModel)]="customer.subscription.plan_overrides.performance_tracking_max_pages"
                    monLabel="{{ 'performance_tracking_max_pages' | monTranslatePlanTraits }}"
                    [monPlaceholder]="defaultOptionTranslation"
                    [type]="'number'">
                </mon-form-field-input>
                <mon-form-field-input
                    [name]="'performance_tracking_max_profiles'"
                    [(ngModel)]="customer.subscription.plan_overrides.performance_tracking_max_profiles"
                    monLabel="{{ 'performance_tracking_max_profiles' | monTranslatePlanTraits }}"
                    [monPlaceholder]="defaultOptionTranslation"
                    [type]="'number'">
                </mon-form-field-input>
                <mon-form-field-select
                    [monName]="'performance_tracking_on_demand_scan'"
                    monLabel="{{ 'performance_tracking_on_demand_scan' | monTranslatePlanTraits }}"
                    (changeEvent)="selectionChange.emit()"
                    monReturnValue="value"
                    [monAllowClear]="true"
                    [monPlaceholder]="defaultOptionTranslation"
                    [monOptions]="booleanDropdownOptions"
                    (modelChange)="selectionChange.emit()"
                    [(model)]="customer.subscription.plan_overrides.performance_tracking_on_demand_scan">
                </mon-form-field-select>
            </div>
        </div>
        <mon-form-field-select [monName]="'prioritized_content_all'"
            monLabel="{{ 'prioritized_content_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.prioritized_content_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'history_center_all'"
            monLabel="{{ 'history_center_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.history_center_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'report_center_all'"
            monLabel="{{ 'report_center_all' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.report_center_all">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'page_assist'"
            monLabel="{{ 'page_assist' | monTranslatePlanTraits }}"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            (changeEvent)="selectionChange.emit()"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.page_assist">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'page_fix'"
            monLabel="{{ 'page_fix' | monTranslatePlanTraits }}"
            (changeEvent)="selectionChange.emit()"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.page_fix">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'advanced_analytics'"
            monLabel="{{ 'advanced_analytics' | monTranslatePlanTraits }}"
            (changeEvent)="selectionChange.emit()"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.advanced_analytics">
        </mon-form-field-select>
    </div>

    <h4 class="unique-trait-override-title" translate>Heatmaps</h4>
    <div class="card-body">
        <mon-form-field-select [monName]="'heatmap_click'"
            monLabel="{{ 'heatmap_click' | monTranslatePlanTraits }}"
            monReturnValue="value"
            (changeEvent)="selectionChange.emit()"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.heatmap_click">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'heatmap_movement'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'heatmap_movement' | monTranslatePlanTraits }}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.heatmap_movement">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'heatmap_scroll'"
            monLabel="{{ 'heatmap_scroll' | monTranslatePlanTraits }}"
            monReturnValue="value"
            (changeEvent)="selectionChange.emit()"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.heatmap_scroll">
        </mon-form-field-select>
        <mon-form-field-input
            [name]="'heatmaps_tracked_number_of_sessions'"
            [(ngModel)]="customer.subscription.plan_overrides.heatmaps_monthly_sessions"
            monLabel="{{ 'heatmaps_monthly_sessions' | monTranslatePlanTraits }}"
            [monPlaceholder]="defaultOptionTranslation"
            [type]="'number'">
        </mon-form-field-input>
        <mon-form-field-input
            [name]="'heatmaps_tracked_number_of_pages'"
            [(ngModel)]="customer.subscription.plan_overrides.heatmaps_tracked_number_of_pages"
            monLabel="{{ 'heatmaps_tracked_number_of_pages' | monTranslatePlanTraits }}"
            [monPlaceholder]="defaultOptionTranslation"
            [type]="'number'">
        </mon-form-field-input>
        <mon-form-field-input
            [name]="'plan_overrides_heatmaps_traffic_percentage'"
            [(ngModel)]="customer.subscription.plan_overrides.heatmaps_traffic_percentage"
            monLabel="{{ 'heatmaps_traffic_percentage' | monTranslatePlanTraits }}"
            [monPlaceholder]="'Use Plan setting' | translate"
            [type]="'number'"
            #heatmaps_traffic_percentage="ngModel"
            [min]="0"
            [max]="100"
            [monMin]="0"
            [monMax]="100">
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="heatmaps_traffic_percentage.control.touched && heatmaps_traffic_percentage.control.invalid"
            [errors]="heatmaps_traffic_percentage.control.errors">
        </mon-form-field-errors>
    </div>

    <h4 class="unique-trait-override-title" translate>UI features</h4>
    <div class="card-body">
        <mon-form-field-select [monName]="'data_export'"
            monLabel="{{ 'data_export' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.data_export">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'monsido_score'"
                                monLabel="{{ 'monsido_score' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.monsido_score">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'page_details'"
                                monLabel="{{ 'page_details' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.page_details">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'script_setup_guide'"
                            monLabel="{{ 'script_setup_guide' | monTranslatePlanTraits}}"
                            (changeEvent)="selectionChange.emit()"
                            [monReturnValue]="'value'"
                            [monAllowClear]="true"
                            [monPlaceholder]="defaultOptionTranslation"
                            [monOptions]="booleanDropdownOptions"
                            [(model)]="customer.subscription.plan_overrides.script_setup_guide">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'quick_search'"
                                monLabel="{{ 'quick_search' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.quick_search">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'account_settings'"
                                monLabel="{{ 'account_settings' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.account_settings">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'dashboard_important_notifications'"
                                monLabel="{{ 'dashboard_important_notifications' | monTranslatePlanTraits}}"
                                (changeEvent)="selectionChange.emit()"
                                [monReturnValue]="'value'"
                                [monAllowClear]="true"
                                [monPlaceholder]="defaultOptionTranslation"
                                [monOptions]="booleanDropdownOptions"
                                [(model)]="customer.subscription.plan_overrides.dashboard_important_notifications">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'general_shortcuts'"
            monLabel="{{ 'general_shortcuts' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.general_shortcuts">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'source_code_excludes'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'source_code_excludes' | monTranslatePlanTraits }}"
            monReturnValue="value"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.source_code_excludes">
        </mon-form-field-select>
    </div>

    <h4 class="unique-trait-override-title" translate>Customer support</h4>
    <div class="card-body">
        <mon-form-field-select [monName]="'general_book_training'"
            monLabel="{{ 'general_book_training' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.general_book_training">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'general_chat_support'"
            monLabel="{{ 'general_chat_support' | monTranslatePlanTraits}}"
            (changeEvent)="selectionChange.emit()"
            [monReturnValue]="'value'"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.general_chat_support">
        </mon-form-field-select>
    </div>

    <h4 class="unique-trait-override-title" translate>Other</h4>
    <div class="card-body">
        <mon-form-field-select
            [monName]="'multiple_user_agreements'"
            [(model)]="customer.subscription.plan_overrides.multiple_user_agreements"
            (changeEvent)="selectionChange.emit()"
            [monPlaceholder]="defaultOptionTranslation"
            [monAllowClear]="true"
            monLabel="{{ 'multiple_user_agreements' | monTranslatePlanTraits }}"
            [monOptions]="booleanDropdownOptions"
            [monReturnValue]="'value'">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'api_users'"
            monLabel="{{ 'api_users' | monTranslatePlanTraits}}"
            monReturnValue="value"
            (changeEvent)="selectionChange.emit()"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.api_users">
        </mon-form-field-select>
        <mon-form-field-select [monName]="'closed_caption'"
            monLabel="{{ 'accessibility_closed_caption' | monTranslatePlanTraits }}"
            [monReturnValue]="'value'"
            (changeEvent)="selectionChange.emit()"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.closed_caption">
        </mon-form-field-select>
        <mon-form-field-select
            [monName]="'skip_on_hold'"
            (changeEvent)="selectionChange.emit()"
            monLabel="{{ 'skip_on_hold' | monTranslatePlanTraits}}"
            monReturnValue="value"
            monHelpText="{{ 'Warning! Only enable this if specifically requested by the customer and they fully understand the consequences' | translate }}"
            [monAllowClear]="true"
            [monPlaceholder]="defaultOptionTranslation"
            [monOptions]="booleanDropdownOptions"
            [(model)]="customer.subscription.plan_overrides.skip_on_hold">
        </mon-form-field-select>
    </div>
</div>
